import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginModel } from '../../models/login.model';
import { PrivilegesModel } from '../../models/privileges.model';
import { AppSettings } from '../../common/app.settings';
import { AuthenticationService } from '../../services/authentication.service';
import { PrivilegesService } from '../../services/privileges.service';
import { UserService } from '../../services/user.service';
import { ResultCode } from "../../enums/resultcode.enum";
import { ApiResult } from "../../models/apiresult.model";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService, PrivilegesService]
})
export class LoginComponent implements OnInit {
  loading = false;
  error = '';

  constructor(private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private privilegesService: PrivilegesService) { }

  ngOnInit() {
    if (this.authService.isTokenExpired) {
      this.privilegesService.logOut();
  }
  }

  login(event, username, password) {
    event.preventDefault();
    this.authService.login(username, password)
    .then((apiResult: ApiResult) => {

      if (apiResult.code === ResultCode.Ok && apiResult.data) {
          this.router.navigate(['home']);
        } else {
            // login failed
            this.error = 'Benutzername oder Passwort falsch';
            this.loading = false;
        }
    });
  }
}
