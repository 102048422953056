import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { PrivilegesService } from '../../services/privileges.service';
import { UserService } from '../../services/user.service';
import { ContentHeaders } from '../../common/headers';
import { ResultCode } from "../../enums/resultcode.enum";
import { ApiResult } from "../../models/apiresult.model";
import { RegisterModel } from '../../models/register.model';

@Component({
  selector: 'register',
  templateUrl: './register.user.component.html',
  styleUrls: ['./register.user.component.css'],
  providers: [UserService, PrivilegesService, ContentHeaders]
})
export class RegisterUserComponent implements OnInit {
  loading = false;
  isError = false;
  isDataAvailable = false;
  captchaData: any;
  messages: Array<string>;
  registerModel: RegisterModel;

  constructor(private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private privilegesService: PrivilegesService) { 
      this.initModel();
    }

  ngOnInit() {
    this.userService.getCaptchaImage()
            .then(result => {
                if (result.result) {
                    this.messages = null;
                    this.captchaData = result.data;
                    this.registerModel.captchaId = result.captchaGuid;
                } else {
                    this.messages = ["Es konnte kein Captcha erzeugt werden."];
                    this.isError = true;
                }
                this.isDataAvailable = true;
            });
  }

  private initModel(): void {
    this.registerModel = new RegisterModel();
    this.registerModel.username = '';
    this.registerModel.mailaddress = '';
    this.registerModel.captchaId = '';
    this.registerModel.captchaText = '';
  }

  startRegistration() {
    event.preventDefault();
        
    this.authService.register(this.registerModel)

    .then((apiResult: ApiResult) => {

      if (apiResult.code === ResultCode.Ok) {
          this.messages = [];
          this.isError = false;
          this.messages = ["Bitte prüfen Sie Ihr E-Mail-Postfach, um den Vorgang abzuschließen."];
          this.loading = false;
      } else {
          // login failed
          this.messages = [];
          this.isError = true;
          this.messages = apiResult.messages;
          this.loading = false;
        }
    });
  }
}
