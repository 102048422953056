import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../common/app.settings';
import { QuizRun } from '../models/quiz.run.model';
import { ApiResult } from '../models/apiresult.model';

@Injectable()
export class QuizRunService {
    private apiController = '/QuizRun';

    constructor(
        private httpClient: HttpClient) {
    }

    getQuizRuns(questionGroupId: string): Promise<QuizRun[]> {
        // get users from api
        return this.httpClient.get(AppSettings.apiEndPoint + this.apiController + '/GetQuizRuns/' + questionGroupId)
        .pipe(
            map((response: QuizRun[]) => {
                return response;
            }))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    placeAnswer(questionId: string, answerNumber: number): Promise<ApiResult> {
        console.log(questionId);
        console.log(answerNumber);
        return this.httpClient.post(
            AppSettings.apiEndPoint + this.apiController + '/PlaceAnswer/' + questionId + '/' + answerNumber,
                { }
            )
            .toPromise()
            .then((response: ApiResult) => response)
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    private getErrorMessage(error: any): string {
        try {
            error = JSON.parse(error._body);
        } catch (e) {
        }

        let errMsg = error.errorMessage
            ? error.errorMessage
            : error.message
            ? error.message
            : error._body
            ? error._body
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'unknown server error';

        console.error(errMsg);
        return errMsg;
    }
}
