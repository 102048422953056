import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './authentication.service';

import { PrivilegesModel } from '../models/privileges.model';

@Injectable()
export class PrivilegesService {
    private privileges: PrivilegesModel;
    private apiController = 'Privileges';

    constructor(
        private authService: AuthenticationService,
        private cookieService: CookieService) {
    }

    private useSecure(): boolean {
        return window.location.href.indexOf('localhost') === -1;
    }

    logOut(): void {
        this.removePrivileges();
    }

    private removePrivileges(): void {
        this.cookieService.delete('menu');
        this.cookieService.delete('hasResetPassword');
        this.cookieService.delete('isadmin');
        this.cookieService.delete('isdev');
        this.cookieService.delete('startPage');
    }

    private handleErrorPromise(error: any): Promise<void> {
        try {
            error = JSON.parse(error._body);
        } catch (e) {
        }

        const errMsg = error.errorMessage
            ? error.errorMessage
            : error.message
            ? error.message
            : error._body
            ? error._body
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'unknown server error';

        return Promise.reject(errMsg);
    }
}
