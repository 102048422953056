<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" (click)="toggleMobileMenu()">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar" [collapse]="!isMobileMenuOpen()">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 navbar-left">
            <li *ngIf="display()" [routerLinkActive]="['active']" (click)="toggleState()"><a  [routerLink]="['/home']">Startseite</a></li>
        </ul>
        <ul *ngIf="display()" class="nav navbar-nav navbar-right">
            <li><i *ngIf="isCollapsed" class="glyphicon glyphicon-lock"></i><a class="logout" [routerLink]="['/logout']">Abmelden</a></li>
        </ul>
        <ul *ngIf="!display()" class="nav navbar-nav navbar-right">
            <li><i *ngIf="isCollapsed" class="glyphicon glyphicon-lock"></i><a class="login" [routerLink]="['/login']">Anmelden</a></li>
        </ul>
    </div>
</nav>
<div class="header-bar"></div>
























