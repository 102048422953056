export class LocationModel {
    id: number;
    questionId: string;
    name: string;
    filename: string;
    fileEditDate: string;
    title: string;
    questionText: string;
    externalLink: string;
    question: string;
    description: string;
    answer1: string;
    answer2: string;
    answer3: string;
    validNumber: number;
   constructor(id: number, name: string, filename: string, fileEditDate: string, title: string, question: string, description: string, externalLink: string, answer1: string, answer2: string, answer3: string, validNumber: number) { 
        this.id = id;
        this.name = name;
        this.filename = filename;
        this.title = title;
        this.question = question;
        this.description = description;
        this.answer1 = answer1;
        this.answer2 = answer2;
        this.answer3 = answer3;
        this.description = description;
        this.validNumber = validNumber;
   }
}