import { Injectable } from "@angular/core";
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ContentHeaders {

    constructor(private authService: AuthenticationService) {

    }

    get headers(): Headers {

        let token = this.authService.getToken();

        return new Headers({
            'name': 'header',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        });
    }

    public headersWithContentType(contentType: string): Headers {

        let token = this.authService.getToken();

        return new Headers({
            'name': 'header',
            'Content-Type': contentType,
            'Accept': contentType,
            'Authorization': 'Bearer ' + token
        });
    }

    public headersWithoutContentType(): Headers {

        let token = this.authService.getToken();

        return new Headers({
            'name': 'header',
            'Authorization': 'Bearer ' + token
        });
    }
}

