import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ResetPasswordModel } from '../../models/reset.password.model';
import { AuthenticationService } from '../../services/authentication.service';
import { PrivilegesService } from '../../services/privileges.service';
import { UserService } from '../../services/user.service';
import { ContentHeaders } from '../../common/headers';
import { ErrorHandler } from '../../common/errorHandler';
import { ResultCode } from '../../enums/resultcode.enum';

@Component({
    selector: 'reset-password',
    templateUrl: './reset.password.component.html',
    styleUrls: ['./reset.password.component.css'],
    providers: [UserService, ErrorHandler, PrivilegesService, ContentHeaders]
})
export class ResetPasswordComponent {
    isError = false;
    isDataAvailable = false;
    messages: Array<string>;
    captchaData: any;
    resetPasswordModel: ResetPasswordModel;

    constructor(private router: Router,
        private authService: AuthenticationService,
        private userService: UserService,
        private privilegesService: PrivilegesService,
        private errorHandler: ErrorHandler
    ) {
        this.initResetPasswordModel();
    }

    ngOnInit() {
        this.userService.getCaptchaImage()
            .then(result => {
                if (result.result) {
                    this.messages = null;
                    this.captchaData = result.data;
                    this.resetPasswordModel.captchaId = result.captchaGuid;
                } else {
                    this.messages = ["Es konnte kein Captcha erzeugt werden."];
                    this.isError = true;
                }
                this.isDataAvailable = true;
            });
    }

    private initResetPasswordModel(): void {
        this.resetPasswordModel = new ResetPasswordModel();
    }

    cancel(): void {
        this.router.navigate(["home"]);
    }

    save(): void {
        this.isDataAvailable = false;

        this.userService.requestPasswordReset(this.resetPasswordModel)
            .then(result => {
                console.log(result);
                this.isDataAvailable = true;

                if (result.code === ResultCode.Ok) {
                    this.initResetPasswordModel();
                    this.messages = ["Bitte prüfen Sie Ihr E-Mail-Postfach, um den Vorgang abzuschließen."];
                    this.isError = false;
                    setTimeout(() => {
                        this.router.navigate(["home"]);
                    }, 5000);
                } else {
                    this.isError = true;
                    if (result.code === ResultCode.GraphApiError) {
                        this.messages = ["Ihre Eingaben wurden nicht akzeptiert."];
                    } else {
                        this.messages = result.messages;
                    }
                }
            })
            .catch(error => {
                this.errorHandler.do(error);
            });
    }
}
