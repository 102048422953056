import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../common/app.settings';
import { LoginModel } from '../models/login.model';
import { RegisterModel } from '../models/register.model';
import jwt_decode from "jwt-decode";
import { JwtPayload } from 'jwt-decode';
import { ApiResult } from '../models/apiresult.model';
 
@Injectable()
export class AuthenticationService {
 
    constructor(private http: HttpClient) {
    }

    getToken(): string {
        return localStorage.getItem('token');
    }

    getRefreshToken(): string {
        return localStorage.getItem('refreshToken');
    }

    getCurrentUser(): any {
        return localStorage.getItem('currentUser');
    }

    isAuthenticated(): boolean {
        return this.getToken() === null;
    }

    private storeLoginData(token: string, refreshToken: string, username: string, mailaddress: string, firstname: string, lastname: string): void {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('currentUser', username);
        localStorage.setItem('currentMailaddress', mailaddress);
        localStorage.setItem('currentFirstname', firstname);
        localStorage.setItem('currentLastname', lastname);
        //localStorage.setItem('currentUser', JSON.stringify({ username: username, token: token, firstname: response.json().data.firstname, lastname: response.json().data.lastname}));
    }

    getTokenExpirationDate(token: string): Date {
        var decoded: JwtPayload = jwt_decode(token);

        if (decoded.exp === undefined) return null;

        const date = new Date(0); 
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(): boolean {
        let token = this.getToken();
        if (!token) {
            return true;
        }

        const date = this.getTokenExpirationDate(token);

        if (date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
    }

    login(username: string, password: string): Promise<ApiResult> {
        let serverUrl = AppSettings.apiEndPoint + '/login';

        const login: LoginModel = {
            username: username,
            password: password
        };

        this.logout();

        return this.http.post<ApiResult>(serverUrl, login)
            .pipe(map((response: ApiResult) => {
                console.log(response);
                if (response && response.code === 0) {
                    let token = response.data.token;
                    console.log(token);
                    let refreshToken = response.data.refreshToken;
                    if (token) {
                        this.storeLoginData(token, refreshToken, response.data.username, response.data.mailaddress, response.data.firstname, response.data.lastname);
                    }
                }
                return response;
            }))
            .toPromise<ApiResult>()
            .catch(this.handleErrorPromise);
    }

    register(register: RegisterModel): Promise<ApiResult> {
        let serverUrl = AppSettings.apiEndPoint + '/register';
       
        this.logout();

        return this.http.post<ApiResult>(serverUrl, register)
            .pipe(map((response: ApiResult) => {
                return response;
            }))
            .toPromise<ApiResult>()
            .catch(this.handleErrorPromise);
    }

    refreshToken(contentHeaders: HttpHeaders): Observable<string> {
        const serverUrl = AppSettings.apiEndPoint + 'Login/Refresh';
        return this.http.post<ApiResult>(serverUrl, {}, { headers: contentHeaders })
            .pipe(map((response: ApiResult) => {
                if (response && response.code === 0) {
                    let token = response.data.token;
                    let refreshToken = response.data.refreshToken;
                    if (token) {
                        this.storeLoginData(token, refreshToken, response.data.username, response.data.mailaddress, response.data.firstname, response.data.lastname);
                        return token;
                    }
                    return '';
                }
                return '';
            }),
            catchError(this.handleErrorPromise));
    }

    logout(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentMailaddress');
        localStorage.removeItem('currentFirstname');
        localStorage.removeItem('currentLastname');
    }

    private handleErrorPromise(error: any): Promise<any> {
        try {
            error = JSON.parse(error._body);
        } catch (e) {
        }

        const errMsg = error.errorMessage
            ? error.errorMessage
            : error.message
            ? error.message
            : error._body
            ? error._body
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'unknown server error';

        console.error(errMsg);
        return Promise.reject(errMsg);
    }
}
