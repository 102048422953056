import { Component } from '@angular/core';
import { McBreadcrumbsModule } from 'omi-breadcrumbs';
import { Router } from '@angular/router';
import { AppSettings } from '../../common/app.settings';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  data: any;
  constructor(private router: Router) {
    this.setAppSettings();
  }

  ngOnInit() {
  }

  private setAppSettings(): void {
    console.log(environment.apiEndPoint);
      AppSettings.apiEndPoint = environment.apiEndPoint;
      AppSettings.envName = environment.envName;
      AppSettings.questionGroupId = environment.questionGroupId;
  }
}
