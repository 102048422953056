
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { LocationModel } from '../models/location.model';
import { LocationsModel } from '../models/locations.model';
import { AppSettings } from '../common/app.settings';

@Injectable()
export class LocationService {
    private apiController = '/Question';
    location = new LocationModel(0, '', 'IMG_0000.JPG', '21-12-02-12-00', '', '', '', '', '', '', '', 1);
    lastIndexes = [0];

    constructor(
        private http: HttpClient) {
    }

    getNewLocationFromLocal(): Promise<any> {
        return this.http.get('../assets/locations.json')
        .pipe(
            map((response: LocationsModel) => {
                var locations = response.locations;
                if (this.lastIndexes.length === locations.length) {
                    this.lastIndexes = [0];
                }
                var newIndex = this.getRandomInt(0, locations.length - 1);
                this.lastIndexes.push(newIndex);
                this.location = locations[newIndex];
                return this.location;
            }))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    getMaxQuestions(): Promise<any> {
        return this.http.get(AppSettings.apiEndPoint + this.apiController + '/PublicQuestions/' + AppSettings.questionGroupId)
        .pipe(
            map((response: LocationsModel) => {
                return response.maxAnswers;
            }))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    getNewLocation(): Promise<LocationModel> {
        return this.http.get(AppSettings.apiEndPoint + this.apiController + '/PublicQuestions/' + AppSettings.questionGroupId)
        .pipe(
            map((response: LocationsModel) => {
                var locations = response.locations;
                if (this.lastIndexes.length === locations.length) {
                    this.lastIndexes = [0];
                }
                var newIndex = this.getRandomInt(0, locations.length - 1);
                this.lastIndexes.push(newIndex);
                this.location = locations[newIndex];
                return this.location;
            }))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    getLocationFromsLocal(): Promise<LocationModel[]> {
        return this.http.get('../assets/locations.json')
        .pipe(
            map((response: LocationsModel) => response.locations))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    getLocations(): Promise<LocationModel[]> {
        return this.http.get(AppSettings.apiEndPoint + this.apiController + '/PublicQuestions')
        .pipe(
            map((response: LocationsModel) => response.locations))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    private getRandomInt(min, max): number {
        var newIndex = Math.floor(Math.random() * (max - min + 1)) + min;
        while (this.arrayContainsNewIndex(newIndex)) {
          newIndex = Math.floor(Math.random() * (max - min + 1)) + min;
        }
        return newIndex;
      }

      private arrayContainsNewIndex(newIndex: number) {
        var arrayContainsNewIndex = false;
        this.lastIndexes.forEach(index => {
          if(index === newIndex) {
            arrayContainsNewIndex = true;
          }
        })
        return arrayContainsNewIndex;
      }
   
      private getErrorMessage(error: any): string {
        try {
            error = JSON.parse(error._body);
        } catch (e) {
        }

        let errMsg = error.errorMessage
            ? error.errorMessage
            : error.message
            ? error.message
            : error._body
            ? error._body
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'unknown server error';

        console.error(errMsg);
        return errMsg;
    }
}
