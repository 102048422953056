import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, throwError } from 'rxjs';
import { switchMap, take, filter, map, catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  constructor(private authenticationService: AuthenticationService) {}
      intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

      let clone: HttpRequest<any>;
      if (!request.url.includes('Login/Refresh')
      && !request.url.includes('login')
      && !request.url.includes('logout')
      && !request.url.includes('register')) {
        clone = this.addAuthenticationToken(request);
      } else {
        clone = this.addHeader(request);
      }

      return next.handle(clone).pipe(catchError((error) => {
            const token = this.authenticationService.getToken();
            const refreshToken = this.authenticationService.getRefreshToken();
            if (
                request.url.includes('Login/Refresh') ||
                request.url.includes('login') ||
                request.url.includes('logout') ||
                request.url.includes('register')
            ) {
                if (request.url.includes('Login/Refresh')) {
                    this.authenticationService.logout();
                }

                return throwError(error);
            }

            if (error.status !== 401) {
                return throwError(error);
            }
           
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                  filter(result => result !== null),
                  take(1),
                  switchMap(() => next.handle(this.addAuthenticationToken(request)))
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);

              return this.authenticationService
                  .refreshToken(new HttpHeaders({
                    'name': 'header',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'RefreshToken': refreshToken
                })).pipe(
                    switchMap((result) => {
                        this.refreshTokenInProgress = false;
                        this.refreshTokenSubject.next(result);

                        return next.handle(this.addAuthenticationToken(request));
                    }),
                    catchError((error) => {
                      this.refreshTokenInProgress = false;

                      this.authenticationService.logout();
                      return throwError(error);
                    })
                  );
            }
        }));
    }

  addAuthenticationToken(request) {
    const token = this.authenticationService.getToken();
    const refreshToken = this.authenticationService.getRefreshToken();

    console.log('addAuthenticationToken -> url: ' + request.url + ', token: ' + token);

    if (!token) {
        return this.addHeader(request);
    }

    return request.clone({
      setHeaders: {
        Accept: `application/json`,
        'Content-Type': `application/json`,
        'Authorization': 'Bearer ' + token,
        'RefreshToken': refreshToken
      }
    });
  }

  addHeader(request) {
    return request.clone({
      setHeaders: {
        Accept: `application/json`,
        'Content-Type': `application/json`
      }
    });
  }
}


