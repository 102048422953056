<h1>Impressum</h1>
<p>
    <strong>RH IT-Solutions GmbH</strong><br />
    Michael C. Reimers<br />
    Zwijndrechtring 80<br />
    22846 Norderstedt
</p>
<p>
    Fon: +49-40-241825-77<br />
    Fax: +49-40-241825-78<br />
    Mobil: +49-163-2507369<br />
    eMail: <a href="mailto:mcr@rhits.de">mcr@rhits.de</a><br />
    USt.-IdNr.: DE287008443<br />
    Geschäftsführer: Michael C. Reimers<br />
    Registerart und -sitz Amtsgericht Kiel<br />
    Registernummer HRB 14570 KI
</p>
<p>
    Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV: Michael C. Reimers<br />
    (Anschrift wie oben)
</p>
<p>
    Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
</p>
