import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.isTokenExpired()) {
            // logged in so return true
            return true;
        } else {
            let token = this.authService.getToken();
            let refreshToken = this.authService.getRefreshToken();

            if(refreshToken === undefined || refreshToken == null) {
                this.router.navigate(['/login']);
                return false;
            }

            this.authService.refreshToken(new HttpHeaders({
                'name': 'header',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
                'RefreshToken': refreshToken
            })).subscribe(result => {
                token = result;
                if (!this.authService.isTokenExpired()) {
                    this.router.navigate([state.url]);
                    return true;
                }

                // not logged in so redirect to login page
                this.router.navigate(['/login']);
                return false;
            });
        }
    }
}
