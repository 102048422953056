import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../common/app.settings';
import { DomSanitizer } from '@angular/platform-browser';
import { LocationService } from '../../services/location.service';
import { ContentHeaders } from '../../common/headers';
import { QuizRunService } from '../../services/quiz.run.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LocationModel } from '../../models/location.model';
import { ApiResult } from '../../models/apiresult.model';
import { ResultCode } from '../../enums/resultcode.enum';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [LocationService, QuizRunService, AuthenticationService, ContentHeaders]
})
export class HomeComponent {
    greetings: string;
    location = new LocationModel(0, '', 'IMG_0000.JPG', '21-12-02-12-00','', '', '', '','', '', '', 1);
    imagePath = '';
    lastIndexes = [0];
    isCorrect = false;
    isAnswered = false;
    isBtnEnabled = true;
    counter = 0;
    maxQuestions = 5;
    correctAnswers = 0;
    isFinished = false;
    isQuestionVisible = true;
    button1Color: string = 'primary';
    button2Color: string = 'primary';
    button3Color: string = 'primary';

    constructor(private router: Router,
        private locationService: LocationService,
        private authService: AuthenticationService,
        private quizRunService: QuizRunService,
        private sanitizer: DomSanitizer) {
        this.locationService = locationService;
    }

    ngOnInit() {
        this.greetings = 'Willkommen beim Norderstedt-Quiz';
        var image = document.getElementById('questionImage');
        this.getMaxQuestions();
        this.start();
    }

    public setImageSize() {
      document.getElementById('questionImage').style.height = (screen.height - 100).toString();
      document.getElementById('questionImage').style.maxHeight = (screen.height - 100).toString();
    }

    public answer(btnNumber: number): void {
        this.isQuestionVisible = true;
        this.isAnswered = true;
        this.isCorrect = false;
        this.isBtnEnabled = false;

        if(!this.authService.isTokenExpired()) {
          this.quizRunService.placeAnswer(this.location.questionId, btnNumber).then((apiResult: ApiResult) => {
            console.log(apiResult);
            if (apiResult.code === ResultCode.Ok && apiResult.data === true) {
              this.isCorrect = true;
              this.correctAnswers++;
            }
            this.setButtonColor();

            if (this.counter === this.maxQuestions) {
              this.isFinished = true;
            }
          });   
        } else {
          if (this.location.validNumber === btnNumber) {
            this.isCorrect = true;
            this.correctAnswers++;
          }
          this.setButtonColor();

          if (this.counter === this.maxQuestions) {
            this.isFinished = true;
          }
        }
      }

      public start(): void {
        this.counter = 0;
        this.correctAnswers = 0;
        this.isFinished = false;
        this.next();
      }

      public hideQuestion(): void {
        this.isQuestionVisible = false;
      }

      public showQuestion(): void {
        this.isQuestionVisible = true;
      }

      public getMaxQuestions(): void {
        this.counter++;
        this.locationService.getMaxQuestions()
        .then(response => {
          this.maxQuestions = response;
        })
        .catch(error => {
          console.log(error);
        });
      }

      public next(): void {
        this.counter++;
        this.locationService.getNewLocation()
        .then(location => {
          this.setImageSize();
          this.location = location;
          this.imagePath = AppSettings.apiEndPoint + '/images/' + this.location.filename;
          this.isQuestionVisible = true;
          this.isAnswered = false;
          this.isCorrect = false;
          this.isBtnEnabled = true;
          this.button1Color = 'primary';
          this.button2Color = 'primary';
          this.button3Color = 'primary';
        })
        .catch(error => {
          console.log(error);
        });
      }

      private setButtonColor(): void {
        if (this.location.validNumber === 1) {
          this.button1Color = 'secondary';
          this.button2Color = 'danger';
          this.button3Color = 'danger';
        } else if (this.location.validNumber === 2) {
          this.button1Color = 'danger';
          this.button2Color = 'secondary';
          this.button3Color = 'danger';
        } else {
          this.button1Color = 'danger';
          this.button2Color = 'danger';
          this.button3Color = 'secondary';
        }
      }
}
