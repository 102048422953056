<div class="footer navbar navbar-default navbar-fixed-bottom">
    <div class="container">
        <div class="navbar-text pull-left">&copy; <span class="hidden-xs">copyright </span>2017</div>
        <div class="navbar-text pull-right">
            <ul>
                <li><a [routerLink]="['/privacy']">Datenschutz</a></li>
                <li><a [routerLink]="['/imprint']">Impressum</a></li>
            </ul>
        </div>
    </div>
</div>