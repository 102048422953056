<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        {{ counter }}. Frage von {{ maxQuestions }} Fragen
    </div>
</div>
<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="flex">
            <img id="questionImage" src="{{ imagePath }}" />
        </div>
        <div class="card-title">
            <div *ngIf="!isQuestionVisible" class="question-info" (click)="showQuestion();">
                <!--ion-icon name="information-circle"></ion-icon-->
            </div>
            <div *ngIf="!isAnswered && isQuestionVisible" class="question" (click)="hideQuestion();">
                {{ location.question }}
            </div>
            <div class="answer">
                <div *ngIf="isAnswered && isCorrect">Richtig!</div>
                <div *ngIf="isAnswered && !isCorrect">Leider falsch.</div>
                <div *ngIf="isFinished && isAnswered">Geschafft! Du hast {{ correctAnswers}} von {{ maxQuestions }} korrekt beantwortet.</div>
                <div *ngIf="isAnswered">{{ location.description }}</div>
                <button *ngIf="!isFinished && isAnswered" class="btn btn-success"  (click)="next();">nächses Bild</button>
                <button *ngIf="isFinished && isAnswered" class="btn btn-success"  (click)="start();">Neustart</button>
            </div>
        </div>
        <div class="btn-group" role="group" aria-label="Basic example">
            <button id="btnAnwer1" class="btn btn-primary" [disabled]="!isBtnEnabled" (click)="answer(1);">{{ location.answer1 }}</button>
            <button id="btnAnwer2" class="btn btn-primary" [disabled]="!isBtnEnabled" (click)="answer(2);">{{ location.answer2 }}</button>
            <button id="btnAnwer3" class="btn btn-primary" [disabled]="!isBtnEnabled" (click)="answer(3);">{{ location.answer3 }}</button>              
        </div>
    </div>
</div>