import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { McBreadcrumbsModule, McBreadcrumbsConfig } from 'omi-breadcrumbs';
import { CookieService } from 'ngx-cookie-service';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { ContentHeaders } from './common/headers';
import { AuthGuard } from './common/auth.guard';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';
import { PrivilegesService } from './services/privileges.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RefreshTokenInterceptor } from './services/token.interceptor.service';

import { AppComponent } from './components/app/app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RegisterUserComponent } from './components/register-user/register.user.component';
import { ResetPasswordComponent } from './components/reset-password/reset.password.component';
import { ChangePasswordComponent } from './components/change-password/change.password.component';
import { ConfirmResetPasswordComponent } from './components/confirm-reset-password/confirm-reset-password.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { PrivacyComponent } from './components/privacy/privacy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    RegisterUserComponent,
    ConfirmResetPasswordComponent,
    HeaderComponent,
    FooterComponent,
    PrivacyComponent,
    ImprintComponent,
    LoaderComponent
  ],
  imports: [
      RouterModule.forRoot([
       { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent},
            { path: 'login', component: LoginComponent, data: { breadcrumbs: true, text: 'Login' } },
            { path: 'logout', component: LogoutComponent, data: { breadcrumbs: true, text: 'Logout' } },
            { path: 'register', component: RegisterUserComponent, data: { breadcrumbs: true, text: 'Registrierung' } },
            { path: 'resetPassword', data: { breadcrumbs: true, text: 'Passwort zurücksetzen' },
                children: [
                    { path: '', component: ResetPasswordComponent},
                    { path: 'confirm/:token', component: ConfirmResetPasswordComponent, data: { breadcrumbs: true, text: 'Bestätigen' }
                },
                ]
            },
            {
                path: 'changePassword',
                component: ChangePasswordComponent,
                canActivate: [AuthGuard],
                data: { breadcrumbs: true, text: 'Passwort ändern' }
            },
            { path: 'privacy', component: PrivacyComponent },
            { path: 'imprint', component: ImprintComponent },
            { path: '**', redirectTo: 'home' }
    ]),
    McBreadcrumbsModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    LoaderComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
      AuthGuard,
      AuthenticationService,
      UserService,
      PrivilegesService,
      CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
