<div class="login">
  <h1>Anmelden</h1>
  <form role="form" (submit)="login($event, username.value, password.value)">
    <div *ngIf="error" class="alert alert-danger">{{error}}</div>
    <div class="form-group">
      <label for="username">Benutzer</label>
      <input type="text" #username class="form-control" id="username" placeholder="">
    </div>
    <div class="form-group">
      <label for="password">Passwort</label>
      <input type="password" #password class="form-control" id="password" placeholder="">
      <!--div class="text-right"><a [routerLink]="['/forgotpassword']">Passwort vergessen?</a></div-->
    </div>
    <div class="text-right button-area">
      <div><button type="submit" class="btn btn-primary">Anmelden</button></div>
    </div>
    <div class="row back-link button-area">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="div-left"><a [routerLink]="['/resetPassword']">Passwort vergessen</a></div>
          <div class="div-right"><a [routerLink]="['/register']">Neu registrieren</a></div>
        </div>
    </div>
  </form>
</div>
