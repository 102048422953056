import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppSettings } from '../common/app.settings';

import { User } from '../models/user.model';
import { ChangePasswordModel } from '../models/change.password.model';
import { ResetPasswordModel } from '../models/reset.password.model';
import { CaptchaModel } from '../models/captcha.model';
import { ApiResult } from '../models/apiresult.model';

@Injectable()
export class UserService {
    private apiController = '/User';

    constructor(
        private http: HttpClient) {
    }

    getUsers(): Promise<User[]> {

        // get users from api
        return this.http.get(AppSettings.apiEndPoint + this.apiController + '/GetUsers')
        .pipe(
            map((response: User[]) => {
                return response;
            }))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    getCaptchaImage(): Promise<CaptchaModel> {
        return this.http.get(
            AppSettings.apiEndPoint + this.apiController + '/GetCaptcha')
            .pipe(
                map((response: CaptchaModel) => response))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    changePassword(changePasswordModel: ChangePasswordModel): Promise<ApiResult> {
        console.log(changePasswordModel);
        return this.http.post(
                AppSettings.apiEndPoint + this.apiController + '/ChangePassword',
                JSON.stringify(changePasswordModel)
            )
            .pipe(
                map((response: ApiResult) => response))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    getUser(userId: string): Promise<User> {
        return this.http.get(AppSettings.apiEndPoint + this.apiController + '/' + userId)
            .pipe(
                map((response: User) => response))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    trackLogin(): Promise<ApiResult> {
        return this.http.post(
            AppSettings.apiEndPoint + this.apiController + '/TrackLogin',
                {}
            )
            .pipe(
                map((response: ApiResult) => response))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    requestPasswordReset(resetPasswordModel: ResetPasswordModel): Promise<ApiResult> {
        return this.http.post(
                AppSettings.apiEndPoint + this.apiController + '/RequestPasswordReset',
                JSON.stringify(resetPasswordModel)
            )
            .pipe(
                map((response: ApiResult) => response))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    resetPassword(token: string): Promise<ApiResult> {
        return this.http.post(
                AppSettings.apiEndPoint + this.apiController + '/ResetPassword?token=' + token,
                {}
            )
            .pipe(
                map((response: ApiResult) => response))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    updateUser(user: User): Promise<ApiResult> {
        return this.http.post(
            AppSettings.apiEndPoint + this.apiController + '/Update/' + user.id,
                JSON.stringify(user)
            )
            .pipe(
                map((response: ApiResult) => response))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    addUser(user: User): Promise<ApiResult> {
        return this.http.post(
            AppSettings.apiEndPoint + this.apiController + '/Add',
                JSON.stringify(user)
            )
            .pipe(
                map((response: ApiResult) => response))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    deleteUser(userId: string): Promise<ApiResult> {
        return this.http.post(
            AppSettings.apiEndPoint + this.apiController + '/Delete/' + userId,
            { }
            )
            .pipe(
                map((response: ApiResult) => response))
            .toPromise()
            .catch(error => {
                let errorMessage = this.getErrorMessage(error);
                console.error(errorMessage);
                return Promise.reject(errorMessage  || 'Server error');
            });
    }

    private getErrorMessage(error: any): string {
        try {
            error = JSON.parse(error._body);
        } catch (e) {
        }

        let errMsg = error.errorMessage
            ? error.errorMessage
            : error.message
            ? error.message
            : error._body
            ? error._body
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'unknown server error';

        console.error(errMsg);
        return errMsg;
    }
}
