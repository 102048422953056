import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
    selector: 'shared-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent {
    private isCollapsed = true;
    private isMobileMenu = false;

    constructor(private router: Router,
        private authService: AuthenticationService) {
    }

    toggleState(): boolean {
        return this.isCollapsed = !this.isCollapsed;
    }

    display(): boolean {
        return !this.authService.isTokenExpired();
    }
    toggleMobileMenu(): void {
        this.isMobileMenu = !this.isMobileMenu;
    }

    closeMobileMenu(): void {
        this.isMobileMenu = false;
    }

    isMobileMenuOpen(): boolean {
        return this.isMobileMenu;
    }

    onResize(): void {
        this.isMobileMenu = false;
    }
}
